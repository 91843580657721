define('frontend-app/pods/application/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax', 'frontend-app/provinces'], function (exports, _environment, _ajax, _provinces) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _Ember$Controller$ext;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = Ember.Controller.extend((_Ember$Controller$ext = {

		intl: Ember.inject.service(),
		windoc: Ember.inject.service(),
		session: Ember.inject.service('session'),
		account: Ember.inject.service(),
		occupations: Ember.inject.service(),

		navigation_visible: false,

		currentRoute: Ember.computed("currentRouteName", function () {
			return this.get("currentRouteName").split(".").join("-");
		})

	}, _defineProperty(_Ember$Controller$ext, 'account', Ember.computed('session.data.authenticated', function () {

		var data = this.get('session.data.authenticated');
		if (!Ember.isEmpty(data)) {
			console.log(data);
			return data;
		}
	})), _defineProperty(_Ember$Controller$ext, 'isShowingModal', false), _defineProperty(_Ember$Controller$ext, 'isPromptingAddAssessment', false), _defineProperty(_Ember$Controller$ext, 'selectedOccupation', null), _defineProperty(_Ember$Controller$ext, 'isError', false), _defineProperty(_Ember$Controller$ext, 'errorMessage', ''), _defineProperty(_Ember$Controller$ext, 'isSuccess', false), _defineProperty(_Ember$Controller$ext, 'successMessage', ''), _defineProperty(_Ember$Controller$ext, 'showCheckout', false), _defineProperty(_Ember$Controller$ext, 'checkoutDescription', "Accelerated Certification Access Fee"), _defineProperty(_Ember$Controller$ext, 'is_processing_payment', false), _defineProperty(_Ember$Controller$ext, 'provinces', _provinces.default), _defineProperty(_Ember$Controller$ext, 'provinceList', Object.keys(_provinces.default).sort()), _defineProperty(_Ember$Controller$ext, 'signup', {
		self_assessments: []
	}), _defineProperty(_Ember$Controller$ext, 'occupationLookup', Ember.computed('model', function () {
		return this.get('model').reduce(function (prev, current, list) {
			prev[current.key] = parseFloat(current.value.price_self_assessment * 100) || 0;
			return prev;
		}, {});
	})), _defineProperty(_Ember$Controller$ext, 'subtotal', Ember.computed('signup.self_assessments.[]', function () {
		var lookup = this.get('occupationLookup');
		return this.get('signup.self_assessments').map(function (item) {
			console.log(item, lookup[item]);
			return lookup[item];
		}).reduce(function (prev, current, list) {
			return parseFloat(prev) + parseFloat(current);
		}, 0);
	})), _defineProperty(_Ember$Controller$ext, 'tax', Ember.computed('subtotal', 'account.data.country', 'account.data.state', function () {

		if (this.get('account.data.country') !== 'Canada') return 0;
		if (!this.get('account.data.state')) return 0;
		if (!('tax' in this.get('provinces')[this.get('account.data.state')])) return 0;

		// return tax as an integer representing the number of cents of tax
		return Math.round(this.get('subtotal') * this.get('provinces')[this.get('account.data.state')].tax);
	})), _defineProperty(_Ember$Controller$ext, 'checkoutTotal', Ember.computed('subtotal', 'tax', function () {

		return this.get('subtotal') + this.get('tax');
	})), _defineProperty(_Ember$Controller$ext, 'validate', function validate() {
		var self = this;
		this.set('isError', false);
		var submission = this.get('signup');

		if (submission.self_assessments.length == 0) {
			this.set('errorMessage', self.get('intl').t('accounts.form.error_missing_assessment'));
			this.set('isError', true);
			return false;
		}
		return true;
	}), _defineProperty(_Ember$Controller$ext, 'actions', {
		toggleNav: function toggleNav() {
			this.toggleProperty("navigation_visible");
		},
		closeCheckout: function closeCheckout() {
			this.set('showCheckout', false);
		},
		processStripeToken: function processStripeToken(token, args) {

			// at this point we have validated input locally, and have a valid Stripe token to process
			// we will process the payment by making a request to /api/accounts/request-account
			// it will validate the request on the server side as it did on the client side
			// if input is valid, it will check to see whether a payment is involved
			// if a payment is involved, it will process the Stripe token first, 
			//   if it goes through, will proceed with the account creation
			//   if it fails, it will notify the user so they can check out again

			var self = this;

			this.set('is_processing_payment', true);

			(0, _ajax.default)(_environment.default.base_url + '/api/assessments/add-assessment', {
				credentials: 'same-origin',
				method: 'post',
				body: JSON.stringify({
					token: token,
					subtotal: self.get('subtotal'),
					tax: self.get('tax'),
					total: self.get('checkoutTotal'),
					details: self.get('signup')
				})

			}).then(function (response) {

				self.set('is_processing_payment', false);
				self.set('showCheckout', false);
				self.set('isError', false);
				self.set('isSuccess', true);
				self.set('successMessage', self.get('intl').t(response.key, response.params || {}));
				self.set('signup', {
					self_assessments: []
				});
				self.get('session.session').restore(); // grab new session data so the self_assessments array is updated
				Ember.run.later(function () {
					self.set('isShowingModal', false);
					self.set('isPromptingAddAssessment', false);
					self.set('isSuccess', false);
					self.set('successMessage', '');
				}, 2500);
			}).catch(function (err) {

				console.log(err);
				self.set('is_processing_payment', false);
				self.set('showCheckout', false);
				self.set('isError', true);
				err.json().then(function (response) {
					self.set('errorMessage', self.get('intl').t(response.key, response.params || {}));
				});
			});
		},
		submitForm: function submitForm() {

			var self = this;

			if (!Ember.run.bind(this, this.get('validate'))()) return false;

			if (this.get('checkoutTotal') > 0) {
				this.set('showCheckout', true);
				return;
			}

			(0, _ajax.default)(_environment.default.base_url + '/api/assessments/add-assessment', {
				method: 'POST',
				body: JSON.stringify({
					token: false,
					details: this.get('signup')
				}),
				credentials: 'same-origin'

			}).then(function (response) {

				self.set('isSuccess', true);
				self.set('successMessage', self.get('intl').t(response.key, response.params || {}));
				self.set('signup', {
					self_assessments: []
				});
				self.get('session.session').restore(); // grab new session data so the self_assessments array is updated
				Ember.run.later(function () {
					self.set('isShowingModal', false);
					self.set('isPromptingAddAssessment', false);
					self.set('isSuccess', false);
					self.set('successMessage', '');
				}, 2500);
			}).catch(function (err) {

				err.json().then(function (response) {
					self.set('isError', true);
					self.set('errorMessage', self.get('intl').t(response.key, response.params || {}));
				});
			});
		}
	}), _Ember$Controller$ext));
});