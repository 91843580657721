define('frontend-app/pods/assessments/view/route', ['exports', 'ember-fetch/ajax', 'frontend-app/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		session: Ember.inject.service(),
		fastboot: Ember.inject.service(),

		model: function model(params) {
			return (0, _ajax.default)(_environment.default.base_url + "/api/assessments/" + params.code, { credentials: 'same-origin' }).then(function (data) {
				return data;
			}).catch(function (err) {
				console.log(err);
			});
		},
		afterModel: function afterModel(model, transition) {
			// abort the transition if the model's status is 'review'
			if (this.get('fastboot.isFastBoot')) return model;
			if (model.status == 'review') {
				alert('You have submitted this assessment for review. It cannot be displayed or edited at this time.');
				this.transitionTo('account.index');
			}
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			controller.set('currentSection', 0);
			controller.set('currentSlide', 0);
			controller.set('isSaving', false);
			if (this.get('fastboot.isFastBoot')) return;
			Ember.run.bind(controller, controller.get('initializeSlideTracker'))();
		},


		actions: {
			willTransition: function willTransition(transition) {
				if (this.controller.get('isModified') && !confirm('You have made changes to your assessment. Do you wish to save before leaving?')) {
					transition.abort();
				} else {
					// Bubble the `willTransition` action so that
					// parent routes can decide whether or not to abort.
					return true;
				}
			}
		}

	});
});