define('frontend-app/pods/careers/index/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax', 'frontend-app/provinces'], function (exports, _environment, _ajax, _provinces) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		session: Ember.inject.service(),
		account: Ember.inject.service(),

		occupations: Ember.inject.service(),

		actions: {}
	});
});