define('frontend-app/pods/account/edit/controller', ['exports', 'frontend-app/config/environment', 'ember-fetch/ajax', 'frontend-app/provinces'], function (exports, _environment, _ajax, _provinces) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		fastboot: Ember.inject.service(),
		account: Ember.inject.service(),
		session: Ember.inject.service(),
		intl: Ember.inject.service(),

		provinces: _provinces.default,
		provinceList: Object.keys(_provinces.default).sort(),

		profile_processing: false,
		profile_error: false,
		profile_success: false,
		profile_message: '',

		change_password_processing: false,
		change_password_success: false,
		change_password_error: false,
		change_password_message: "",

		validate: function validate() {
			var self = this;
			this.set('isError', false);
			var submission = this.get('model');

			var not_empty = ['name', 'surname', 'phone', 'address1', 'city', 'state', 'code', 'country'];

			not_empty = not_empty.filter(function (key) {
				if (self.get('model.' + key) == "") return true;
				return false;
			}).map(function (key) {
				return self.get('intl').t('sign-up.assessment.form.' + key);
			});
			if (not_empty.length > 0) {
				this.set('profile_message', self.get('intl').t('accounts.form.error_missing_fields') + not_empty.join(', '));
				this.set('profile_error', true);
				return false;
			}
			if (submission.self_assessments.length == 0) {
				this.set('profile_message', self.get('intl').t('accounts.form.error_missing_assessment'));
				this.set('profile_error', true);
				return false;
			}
			if (!submission.email) {
				this.set('profile_message', self.get('intl').t('accounts.form.error_email_required'));
				this.set('profile_error', true);
				return false;
			}
			return true;
		},

		actions: {
			setCountry: function setCountry(which) {
				this.set('model.country', which);
				this.set('model.state', '');
			},
			setState: function setState(value, event) {
				this.set('model.state', value);
			},
			submitProfileForm: function submitProfileForm() {
				var self = this;

				if (!Ember.run.bind(this, this.get('validate'))()) return false;

				this.set('profile_success', false);
				this.set('profile_error', false);
				this.set('profile_message', '');
				this.set('profile_processing', true);

				(0, _ajax.default)(_environment.default.base_url + '/api/account/update', {

					credentials: 'same-origin',
					method: 'post',
					body: JSON.stringify(this.get('model'))

				}).then(function (response) {

					self.set('profile_processing', false);
					self.set('profile_success', true);
					self.get('session.session').restore().then(function () {
						self.transitionToRoute('account.index');
					});
				}).catch(function (err) {

					self.set('profile_processing', false);

					err.json().then(function (data) {
						self.set('profile_message', self.get('intl').t('accounts.form.' + data));
						self.set('profile_error', true);
					});
				});
			},
			submitPasswordForm: function submitPasswordForm() {

				var self = this;

				if (this.get('change_password_processing')) return;

				this.set('change_password_processing', true);
				this.set('change_password_success', false);
				this.set('change_password_error', false);
				this.set('change_password_message', '');

				(0, _ajax.default)(_environment.default.base_url + '/api/accounts/change_password', {

					credentials: 'same-origin',
					method: 'post',
					body: JSON.stringify({
						password_old: this.get('pass_old'),
						password_new_1: this.get('pass_new'),
						password_new_2: this.get('pass_new_again')
					})

				}).then(function (response) {

					self.set('change_password_processing', false);
					self.set('change_password_message', response);
					self.set('change_password_success', true);
					self.set('pass_old', '');
					self.set('pass_new', '');
					self.set('pass_new_again', '');
					Ember.run.later(function () {
						self.get('session.session').invalidate();
					}, 5000);
				}).catch(function (err) {

					self.set('change_password_processing', false);

					err.json().then(function (data) {
						self.set('change_password_message', data);
						self.set('change_password_error', true);
					});
				});
			}
		}
	});
});