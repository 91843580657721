define('frontend-app/pods/careers/view/controller', ['exports', 'frontend-app/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		session: Ember.inject.service(),
		account: Ember.inject.service()

	});
});